import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useRef, useEffect, useState } from "react";
import { mobile, mobileUp } from "../styles/global";

const ProgressCursor = ({ activeSlide, total, text }) => {
	let cursorTop, cursorLeft;

	const [top, setTop] = useState(null);
	const [left, setLeft] = useState(null);

	const mouseMoveHandler = (e) => {
		setTop(e.screenY);
		setLeft(e.screenX);
	};

	useEffect(() => {
		window.addEventListener("mousemove", mouseMoveHandler);
	}, []);

	return (
		<CursorWrap
			css={css`
				z-index: 101;
				font-family: var(--hermes);
				font-size: var(--accent-size);
				line-height: var(--accent-line);
				letter-spacing: var(--accent-letter);
				position: fixed;
				top: 0;
				left: 0;
				padding: 19px var(--margin);

				@media (hover: hover) {
					top: ${top}px;
					left: ${left}px;
				}

				@media (hover: none) {
					display: none;
				}

				${mobile} {
					display: none;
				}
			`}
		>
			{activeSlide + 1}/{total}
		</CursorWrap>
	);
};

const CursorWrap = styled.span``;

export default ProgressCursor;
